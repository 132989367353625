/* eslint-disable unicorn/consistent-function-scoping */
import {
  Button,
  ButtonBaseProps,
  Icon,
  Stack,
  styled,
  useMounted
} from '@qcx/ui';
import {
  Children,
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons';

export interface HorizontalScrollerProps
  extends ComponentProps<typeof StyledContainer> {
  children: React.ReactNode;
  prevButtonProps?: ButtonBaseProps;
  nextButtonProps?: ButtonBaseProps;
  slideProps?: ComponentProps<typeof StyledSlide>;
}

export const HorizontalScroller = ({
  children,
  slideProps,
  prevButtonProps,
  nextButtonProps,
  ...props
}: HorizontalScrollerProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const hasPrev = useMemo(() => page > 0, [page]);
  const hasNext = useMemo(() => page < totalPages - 1, [page, totalPages]);

  useMounted(() => {
    const handleResize = () => {
      const viewportWidth = scrollerRef.current?.clientWidth ?? 0;
      const viewportScrollWidth = scrollerRef.current?.scrollWidth ?? 0;
      const totalPages = Math.ceil(viewportScrollWidth / viewportWidth);

      setViewportWidth(scrollerRef.current?.clientWidth || 0);
      setTotalPages(totalPages);
    };

    handleResize();

    window.addEventListener('resize', handleResize, {
      passive: true
    });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollLeft = page * viewportWidth;
    }
  }, [page, viewportWidth]);

  const handleNext = () => {
    if (hasNext) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (hasPrev) {
      setPage(page - 1);
    }
  };

  return (
    <Stack css={{ position: 'relative' }}>
      <Button
        icon={<Icon icon={faChevronLeft} />}
        size="xl"
        iconButton
        {...prevButtonProps}
        aria-label="Anterior"
        disabled={!hasPrev}
        onClick={handlePrev}
      />
      <StyledContainer {...props} ref={scrollerRef}>
        {Children.map(children, child => (
          <StyledSlide {...slideProps}>{child}</StyledSlide>
        ))}
      </StyledContainer>
      <Button
        icon={<Icon icon={faChevronRight} />}
        size="xl"
        iconButton
        {...nextButtonProps}
        aria-label="Próximo"
        disabled={!hasNext}
        onClick={handleNext}
      />
    </Stack>
  );
};

const StyledSlide = styled('div', {
  scrollSnapAlign: 'start'
});

const StyledContainer = styled('div', {
  scrollSnapType: 'x mandatory',
  display: 'flex',
  mx: '$4',
  gap: '$4',
  scrollX: 'auto',
  '@md': {
    scrollX: 'hidden'
  }
});
