import {
  CentroOesteIcon,
  FederalIcon,
  NordesteIcon,
  NorteIcon,
  SudesteIcon,
  SulIcon
} from './icons';

export type RegionName =
  | 'Federal'
  | 'Sudeste'
  | 'Nordeste'
  | 'Norte'
  | 'Sul'
  | 'Centro-Oeste';

const iconMapping = {
  Federal: FederalIcon,
  Sudeste: SudesteIcon,
  Nordeste: NordesteIcon,
  Norte: NorteIcon,
  Sul: SulIcon,
  'Centro-Oeste': CentroOesteIcon
};

interface RegionIconProps extends React.SVGProps<SVGSVGElement> {
  region: RegionName;
  alt: string;
}

export const RegionIcon = ({ region, ...props }: RegionIconProps) => {
  const IconComponent = iconMapping[region];
  return <IconComponent {...props} />;
};
