import { Banner, HomeBanner } from './interventions/HomeBanner';
import { Box } from '@qcx/ui';
import { Highlights } from '../../../components/Highlights';
import {
  HomeHighlights,
  HomeHighlightsProps
} from './interventions/HomeHighlights';
import { RegionLinks } from './RegionLinks';
import { SimpleArticle } from '@atreus/data';
import { StateLinks } from './StateLinks';

interface recentNewsProps {
  nationalHighlights: SimpleArticle[];
  bannerHighlights: HomeHighlightsProps & {
    active: boolean;
  };
  homeBanner: Banner;
}

export function RecentNewsSection({
  nationalHighlights,
  bannerHighlights,
  homeBanner
}: recentNewsProps) {
  return (
    <>
      <HomeBanner
        id={homeBanner.id}
        alt={homeBanner.alt}
        lgImage={homeBanner.lgImage}
        link={homeBanner.link}
        mdImage={homeBanner.mdImage}
        smImage={homeBanner.smImage}
        title={homeBanner.title}
      />
      <Box>
        <RegionLinks
          id="news-by-region"
          title="Notícias por regiões e estados"
          variant="primary"
          fullWidth
          icons
        />
      </Box>
      <Box css={{ mt: '$2' }}>
        <StateLinks />
      </Box>
      <Box css={{ mt: '$4', mb: '$8' }}>
        {nationalHighlights.length > 0 ? (
          <Highlights
            bullets={nationalHighlights.slice(1, 7)}
            post={nationalHighlights[0]}
          />
        ) : null}
      </Box>
      {bannerHighlights.active && <HomeHighlights {...bannerHighlights} />}
    </>
  );
}
