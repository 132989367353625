import { Alert } from './components/Alert';
import { Box, Stack } from '@qcx/ui';
import { Contests } from './components/Contests';
import { ListBullets } from '../../ListBullets';
import { MicroArticle, SimpleArticle } from '@atreus/data';
import { PostSection } from '../../post/PostSection';
import { route } from '../../../route';

interface HighlightsWeekProps {
  highlights: SimpleArticle[];
  contests: {
    opened: MicroArticle[];
    foreseen: MicroArticle[];
  };
}

export const HighlightsWeek = ({
  highlights,
  contests
}: HighlightsWeekProps) => (
  <Stack
    flow={{
      '@initial': 'column',
      '@xl': 'row'
    }}
    gap="$8"
  >
    <Stack
      css={{ flex: 1 }}
      flow={{
        '@initial': 'column',
        '@md': 'row'
      }}
      gap="$8"
    >
      <PostSection
        flow={{
          '@initial': 'column',
          '@xl': 'row'
        }}
        gap="$6"
        postCardProps={{
          css: {
            '& h2': {
              '@xl': {
                fontSize: '$2xl'
              }
            },
            '@lg': { w: 400 }
          },
          flow: 'column',
          priority: false,
          elevated: false,
          variant: 'md'
        }}
        posts={highlights.slice(0, 1)}
        title="As mais lidas da semana"
      />
      <Box
        css={{
          '@initital': {
            mt: '0'
          },
          '@md': {
            mt: '$10'
          }
        }}
      >
        <ListBullets
          bullets={highlights.slice(1, 6)}
          propsWrapper={{ gap: '$8' }}
        />
      </Box>
    </Stack>
    <Stack
      css={{
        '@initial': {
          maxW: '$full'
        },
        '@xl': {
          maxW: 384
        },
        w: '$full',
        flex: 1
      }}
      flow={{
        '@xs': 'column',
        '@sm': 'row',
        '@xl': 'column'
      }}
      gap="$8"
    >
      <Contests title="Concursos Abertos" variant="blue">
        {contests.opened.map((contest, idx) => (
          <Stack key={idx} flow="column" gap="$3">
            <Alert
              title={contest.title}
              to={route('news-show', {
                slug: contest.slug
              })}
              variant={'blue'}
            />
          </Stack>
        ))}
      </Contests>
      <Contests title="Concursos Previstos" variant="secondary">
        {contests.foreseen.map((contest, idx) => (
          <Stack key={idx} flow="column" gap="$3">
            <Alert
              title={contest.title}
              to={route('news-show', {
                slug: contest.slug
              })}
              variant={'secondary'}
            />
          </Stack>
        ))}
      </Contests>
    </Stack>
  </Stack>
);
