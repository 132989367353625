import QcIcon from '../../../../assets/icons/qc/logo.svg';
import { Button, Icon, Image, Stack, Text, styled } from '@qcx/ui';
import { Link } from '@qcx/remix';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { safeRenderHtml } from '../../../../lib/safe-render-html.lib';

export interface HomeHighlightsProps {
  title: string;
  subtitle: string;
  cta: {
    text: string;
    link: string;
    event: string;
    props?: Record<string, string>;
  };
}

export const HomeHighlights = ({
  title,
  subtitle,
  cta
}: HomeHighlightsProps) => (
  <StyledWrapper flow={{ '@initial': 'column', '@md': 'row' }} gap="$6">
    <StyledIcon
      width={72}
      height={72}
      alt="Logo Qconcursos"
      src={QcIcon}
      noAspectRatio
      priority
    />
    <Stack css={{ flex: 1 }} flow="column">
      <Text
        css={{ color: '$neutral900' }}
        variant={{
          '@initial': 'lg',
          '@lg': 'xl'
        }}
        weight="semibold"
      >
        {title}
      </Text>
      <Text css={{ color: '$neutral500' }} variant="md" weight="semibold">
        <span dangerouslySetInnerHTML={{ __html: safeRenderHtml(subtitle) }} />
      </Text>
    </Stack>
    <Button
      as={Link}
      css={{
        bgColor: '$orangeDark600'
      }}
      rightIcon={<Icon icon={faChevronRight} />}
      size="lg"
      tabIndex={0}
      to={cta.link}
      variant="danger"
      {...cta.props}
      onClick={() => cta.event}
    >
      {cta.text}
    </Button>
  </StyledWrapper>
);

const StyledWrapper = styled(Stack, {
  bg: 'linear-gradient(269.63deg, rgba(251, 167, 89, 0.2) 14.05%, rgba(237, 110, 93, 0.2) 83.89%);',
  minH: '$24',
  flexCenter: true,
  px: '$6',
  py: '$7',
  rounded: '$5xl',
  alignItems: 'flex-start',
  '@md': {
    rounded: '$full',
    py: '$6',
    px: '$10',
    alignItems: 'center'
  },
  '@lg': {
    px: '$6',
    py: '$4'
  }
});

const StyledIcon = styled(Image, {
  bgColor: '$hiContrast',
  p: '$4',
  rounded: '$full',
  '& img': {
    h: 40,
    objectFit: 'contain'
  },
  d: 'none',
  '@lg': {
    d: 'flex'
  }
});
