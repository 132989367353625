import QcIcon from '../../../../assets/icons/qc/logo-white.svg';
import { Button, Icon, Image, Stack, Text, styled } from '@qcx/ui';
import { Link } from '@qcx/remix';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { safeRenderHtml } from '../../../../lib/safe-render-html.lib';

export interface HomeNewsProps {
  title: string;
  subtitle: string;
  cta: {
    text: string;
    link: string;
    event: string;
    props?: Record<string, string>;
  };
}

export const HomeNews = ({ title, subtitle, cta }: HomeNewsProps) => (
  <StyledWrapper flow={{ '@initial': 'column', '@md': 'row' }} gap="$6">
    <StyledIcon
      width={72}
      height={72}
      alt="Logo Qconcursos"
      src={QcIcon}
      noAspectRatio
      priority
    />
    <Stack css={{ flex: 1 }} flow="column">
      <Text
        css={{ color: '$neutral900' }}
        variant={{
          '@initial': 'lg',
          '@lg': 'xl'
        }}
        weight="semibold"
      >
        {title}
      </Text>
      <Text css={{ color: '$neutral6 00' }} variant="md" weight="regular">
        <span dangerouslySetInnerHTML={{ __html: safeRenderHtml(subtitle) }} />
      </Text>
    </Stack>
    <Button
      as={Link}
      css={{
        '@md': { minW: 'auto' },
        '@sm': { minW: 280 },
        flexCenter: true,
        bgColor: '$orangeDark600'
      }}
      rightIcon={<Icon icon={faChevronRight} />}
      size="lg"
      tabIndex={0}
      to={cta.link}
      variant="danger"
      {...cta.props}
      onClick={() => cta.event}
    >
      {cta.text}
    </Button>
  </StyledWrapper>
);

const StyledWrapper = styled(Stack, {
  bgColor: '$secondary25',
  minH: '$24',
  flexCenter: true,
  px: '$6',
  py: '$7',
  rounded: '$4xl',
  alignItems: 'flex-start',
  '@md': {
    py: '$6',
    px: '$10',
    alignItems: 'center'
  },
  '@lg': {
    px: '$6',
    py: '$4'
  }
});

const StyledIcon = styled(Image, {
  bgColor: '$secondary400',
  p: '$4',
  rounded: '$full',
  borderWidth: 8,
  borderColor: '$secondary100',
  borderStyle: 'solid',
  '& img': {
    h: 40,
    objectFit: 'contain'
  },
  '@md': {
    d: 'none'
  },
  '@lg': {
    d: 'flex'
  }
});
