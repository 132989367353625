import bannerDesktopSrc from '../../assets/banners/desktop.webp';
import bannerMobileSrc from '../../assets/banners/mobile.webp';
import bannerTabletSrc from '../../assets/banners/tablet.webp';
import { Banner } from '../homepage/components/interventions/HomeBanner';

export const bannerDefault: Banner = {
  id: 'atreus-home-primary-default',
  status: 'published',
  title: 'Assinatura QC',
  alt: 'Assinatura QC',
  description: null,
  link: 'https://www.qconcursos.com/planos-de-assinatura?qtm_source=folha&qtm_medium=banner',
  xsImage: {
    id: '2174bd6d-f9e6-49e4-8612-ae71320dce0d',
    filename_download: 'sm.png',
    description: null,
    width: 1141,
    height: 599,
    title: 'Sm',
    type: 'image/png',
    filename_disk: bannerMobileSrc
  },
  smImage: {
    id: '56d133d4-d084-4acb-9035-03848a2f061e',
    filename_download: 'sm.png',
    description: null,
    width: 1141,
    height: 599,
    title: 'Sm',
    type: 'image/png',
    filename_disk: bannerMobileSrc
  },
  mdImage: {
    id: '3205e462-d9aa-40ce-8c27-2d159fd38d83',
    filename_download: 'md.png',
    description: null,
    width: 1409,
    height: 1283,
    title: 'Md',
    type: 'image/png',
    filename_disk: bannerTabletSrc
  },
  lgImage: {
    id: '6e949c92-ecd3-4e56-97aa-47e91657d41d',
    filename_download: 'lg.png',
    description: null,
    width: 2433,
    height: 297,
    title: 'Lg',
    type: 'image/png',
    filename_disk: bannerDesktopSrc
  },
  sort: null,
  tags: ['atreus'],
  buttons: [],
  createdAt: '2023-12-19T20:23:54.444Z',
  updatedAt: '2024-01-11T14:44:36.288Z'
};
