import { PostSection } from '../../../components/post/PostSection';
import { RegionLinks } from './RegionLinks';
import { SimpleArticle } from '@atreus/data';
import { useMemo, useState } from 'react';

interface HomeTabsRegionProps {
  highlightsByRegions: Record<string, SimpleArticle[]>;
  federalHighlights: SimpleArticle[];
}

export const HomeTabsRegion = ({
  highlightsByRegions,
  federalHighlights
}: HomeTabsRegionProps) => {
  const [filterRegion, setFilterRegion] = useState({ region: 'federal' });

  const highlights = useMemo<Record<string, SimpleArticle[]>>(() => {
    return {
      federal: federalHighlights.slice(0, 3),
      ...highlightsByRegions
    };
  }, [federalHighlights, highlightsByRegions]);

  const handleFilterChange = (value: string) => {
    setFilterRegion({ region: value });
  };
  return (
    <>
      <RegionLinks
        exclude={['nacional']}
        value="federal"
        variant="elevated"
        fullWidth
        onFilterRegion={handleFilterChange}
      />
      <PostSection
        flow={{
          '@initial': 'column',
          '@md': 'row'
        }}
        gap="$6"
        postCardProps={{
          flow: 'column',
          priority: false,
          elevated: false,
          variant: 'md',
          css: {
            '@md': { '& div > div': { w: 230 } },
            '@lg': { '& div > div': { w: 400 } }
          }
        }}
        posts={highlights[filterRegion.region]}
      />
    </>
  );
};
