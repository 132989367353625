import { Box, Icon, Image, Stack, Text, styled } from '@qcx/ui';
import { Editorial } from '@atreus/data';
import { Link } from '@qcx/remix';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons';
import { route } from '../../../route';

export interface ListBalloonsProps {
  name: string;
  slug: string;
  image?: string;
}

export const ListBalloons = ({
  options,
  title
}: {
  title?: string;
  options: ListBalloonsProps[] | Editorial[];
}) => {
  return (
    <>
      {title && (
        <Box css={{ pb: '$6' }}>
          <Text
            as="h2"
            css={{ color: '$neutral500' }}
            variant="sm"
            weight="semibold"
          >
            {title}
          </Text>
        </Box>
      )}
      <Stack
        css={{
          overflowY: 'auto',
          overflowX: 'auto',
          justifyContent: 'flex-start',
          '@xl': {
            overflowY: 'unset',
            overflowX: 'unset',
            justifyContent: 'center'
          }
        }}
        flow="row"
        gap="$8"
      >
        {options.map((option, idx) => (
          <Box key={`option_${idx}`}>
            <Link
              css={{
                bgColor: '$neutral50',
                w: 131,
                h: 176,
                rounded: '$full',
                d: 'flex',
                flexCol: true,
                flexCenter: true,
                color: '$neutral600',
                '&:hover, &:focus, &:active': {
                  bgColor: '$neutral100',
                  color: '$neutral600'
                }
              }}
              to={route('editorials-show', { slug: option.slug })}
            >
              {'image' in option && option.image ? (
                <StyledImage
                  width={96}
                  height={96}
                  alt={option.name}
                  loading="lazy"
                  src={option.image}
                  noAspectRatio
                />
              ) : (
                <Icon
                  css={{
                    bgColor: '$blue50',
                    color: '$primary500',
                    '& svg': {
                      fontSize: '$3xl'
                    }
                  }}
                  icon={faFileCheck}
                  shape="circle"
                  size="5xl"
                />
              )}
              <Box
                css={{
                  h: '$10',
                  px: '$6',
                  mt: '$2',
                  flexCenter: true,
                  textAlign: 'center'
                }}
              >
                <Text
                  css={{ color: 'CurrentColor' }}
                  variant="sm"
                  weight="medium"
                >
                  {option.name}
                </Text>
              </Box>
            </Link>
          </Box>
        ))}
      </Stack>
    </>
  );
};

const StyledImage = styled(Image, {
  rounded: '$full',
  '& img': {
    h: 96,
    objectFit: 'cover'
  }
});
