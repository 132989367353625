import { HorizontalScroller } from './HorizontalScroller';
import { Link } from '@qcx/remix';
import { STATES } from '../../../constants/regions.constants';
import { route } from '../../../route';
import { styled } from '@qcx/ui';

export const StateLinks = () => {
  return (
    <HorizontalScroller
      css={{
        gap: '$2',
        mx: '$2'
      }}
    >
      {STATES.map(state => (
        <StyledButton
          key={state.value}
          aria-label={state.name}
          title={`Notícias do estado: ${state.name}`}
          to={route('editorials-show', { slug: state.name.toLowerCase() })}
        >
          {state.value}
          <StyledButtonSpan>Notícias do estado: {state.name}</StyledButtonSpan>
        </StyledButton>
      ))}
    </HorizontalScroller>
  );
};

const StyledButton = styled(Link, {
  borderWidth: 1,
  border: '1px solid $borderColor',
  color: '$neutral700',
  rounded: '$lg',
  minMaxSize: '$12',
  flexCenter: true,
  fontSize: '$sm',
  fontWeight: '$medium'
});

const StyledButtonSpan = styled('span', {
  size: 1,
  overflow: 'hidden',
  clip: 'rect(0,0,0,0)',
  p: 0
});
