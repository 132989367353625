import { ASSETS_URL } from '../lib/constants/url.lib';

export const HOME_SCHEMA = [
  {
    '@context': 'http://schema.org',
    '@type': 'EducationalOrganization',
    location: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Rio de Janeiro, RJ, Brasil',
        streetAddress:
          'Av. das Américas, 3434 - Barra da Tijuca, Rio de Janeiro - RJ',
        postalCode: '22640-102'
      }
    },
    logo: `${ASSETS_URL}/images/folha/logo.svg`,
    name: 'Folha Dirigida',
    url: 'https://folha.qconcursos.com'
  },
  {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://folha.qconcursos.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://folha.qconcursos.com/busca?q={search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  },
  {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: 'Folha Dirigida - O portal de notícias para concursos públicos',
    description:
      'Fique por dentro das últimas notícias sobre concursos públicos, editais, cursos preparatórios, dicas de estudo, carreira pública e muito mais. Acompanhe também os calendários de provas e resultados.',
    url: 'https://folha.qconcursos.com/',
    image: `${ASSETS_URL}/images/folha/logo.svg`,
    sameAs: [
      'https://www.facebook.com/FolhaDirigida/',
      'https://twitter.com/folhadirigida',
      'https://www.instagram.com/folhadirigida/'
    ]
  }
];
