// A map of region names to their corresponding region codes
export const REGIONS = {
  sudeste: [
    {
      name: 'concursos-espirito-santo-es',
      value: 'ES'
    },
    {
      name: 'concursos-minas-gerais-mg',
      value: 'MG'
    },
    {
      name: 'concursos-rio-de-janeiro-rj',
      value: 'RJ'
    },
    {
      name: 'concursos-sao-paulo-sp',
      value: 'SP'
    }
  ],
  sul: [
    {
      name: 'concursos-parana-pr',
      value: 'PR'
    },
    {
      name: 'concursos-rio-grande-do-sul-rs',
      value: 'RS'
    },
    {
      name: 'concursos-santa-catarina-sc',
      value: 'SC'
    }
  ],
  'centro-oeste': [
    {
      name: 'concursos-distrito-federal-df',
      value: 'DF'
    },
    {
      name: 'concursos-goias-go',
      value: 'GO'
    },
    {
      name: 'concursos-mato-grosso-mt',
      value: 'MT'
    },
    {
      name: 'concursos-mato-grosso-do-sul-ms',
      value: 'MS'
    }
  ],
  norte: [
    {
      name: 'concursos-acre-ac',
      value: 'AC'
    },
    {
      name: 'concursos-amapa-ap',
      value: 'AP'
    },
    {
      name: 'concursos-amazonas-am',
      value: 'AM'
    },
    {
      name: 'concursos-para-pa',
      value: 'PA'
    },
    {
      name: 'concursos-rondonia-ro',
      value: 'RO'
    },
    {
      name: 'concursos-roraima-rr',
      value: 'RR'
    },
    {
      name: 'concursos-tocantins-to',
      value: 'TO'
    }
  ],
  nordeste: [
    {
      name: 'concursos-alagoas-al',
      value: 'AL'
    },
    {
      name: 'concursos-bahia-ba',
      value: 'BA'
    },
    {
      name: 'concursos-ceara-ce',
      value: 'CE'
    },
    {
      name: 'concursos-maranhao-ma',
      value: 'MA'
    },
    {
      name: 'concursos-paraiba-pb',
      value: 'PB'
    },
    {
      name: 'concursos-pernambuco-pe',
      value: 'PE'
    },
    {
      name: 'concursos-piaui-pi',
      value: 'PI'
    },
    {
      name: 'concursos-rio-grande-do-norte-rn',
      value: 'RN'
    },
    {
      name: 'concursos-sergipe-se',
      value: 'SE'
    }
  ]
};

export const STATES: { name: string; value: string }[] = Object.entries(REGIONS)
  .flatMap(([region, states]) =>
    states.map(state => ({
      ...state,
      region
    }))
  )
  .sort((a, b) => a.value.localeCompare(b.value));
