import { Box, Text, styled } from '@qcx/ui';
import { Link } from '@qcx/remix';
import { ListBullets } from './ListBullets';
import { PostSection } from './post/PostSection';
import { SimpleArticle } from '@atreus/data';
import { route } from '../route';

interface HighlightsProps {
  post: SimpleArticle;
  bullets: SimpleArticle[];
}

export const Highlights = ({ post, bullets }: HighlightsProps) => (
  <StyledWrapper>
    <Box>
      <Link
        title={post.title}
        to={route('news-show', {
          slug: post.slug
        })}
      >
        <Text
          as="h2"
          css={{
            color: '#FE6112',
            mb: '$10',
            lineHeight: 'normal',
            fontFamily: '$heading',
            fontSize: '$2xl',
            '@xl': {
              fontSize: '$4xl'
            }
          }}
          weight="bold"
        >
          {post.title}
        </Text>
      </Link>
      <ListBullets bullets={bullets.slice(0, 4)} />
    </Box>
    <Box css={{ '@lg': { ml: '$4' } }}>
      <PostSection
        flow={{
          '@initial': 'column',
          '@md': 'row'
        }}
        gap="$8"
        postCardProps={{
          css: {
            '& h2': {
              '@xl': {
                fontSize: '$2xl'
              }
            },
            '@md': { '& div > div': { w: 343 } },
            '@lg': { '& div > div': { w: 280 } }
          },
          flow: 'column',
          priority: true,
          elevated: false
        }}
        posts={bullets.slice(4, 6)}
      />
    </Box>
  </StyledWrapper>
);

const StyledWrapper = styled('div', {
  flexCol: true,
  gap: '$8',
  d: 'grid',
  '@sm': {
    gridCols: '$1',
    gridRows: '$1'
  },
  '@lg': {
    gridCols: '$2',
    gridRows: '$1'
  }
});
